var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "roleSelection-section vh-center" }, [
    _c("div", [
      _c("h1", [_vm._v("역할 선택")]),
      _c("p", [_vm._v("설비회사와 도입회사를 모두 사용중인 계정입니다.")]),
      _c("p", { staticClass: "mb-20" }, [
        _vm._v("사용하고자 하는 역할을 선택하신 후, 이용하여 주시기 바랍니다."),
      ]),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          {
            staticClass: "mr-30",
            on: {
              click: function ($event) {
                return _vm.setCurrentCompany("M")
              },
            },
          },
          [
            _c("div", [
              _c("h2", [_vm._v("설비 회사")]),
              _c("p", [_vm._v(_vm._s(_vm.equipmentText))]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                return _vm.setCurrentCompany("F")
              },
            },
          },
          [_vm._m(0)]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", [_vm._v("도입 회사")]),
      _c("p", [_vm._v("매니저")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }