<template>
  <section class="roleSelection-section vh-center">
    <div>
      <h1>역할 선택</h1>
      <p>설비회사와 도입회사를 모두 사용중인 계정입니다.</p>
      <p class="mb-20">사용하고자 하는 역할을 선택하신 후, 이용하여 주시기 바랍니다.</p>
      <div class="flex">
        <div class="mr-30" @click="setCurrentCompany('M')">
          <div>
            <h2>설비 회사</h2>
            <p>{{ equipmentText }}</p>
          </div>
        </div>
        <div @click="setCurrentCompany('F')">
          <div>
            <h2>도입 회사</h2>
            <p>매니저</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'RoleSelection',
  props: [],
  data() {
    return {
      equipmentText: '',
      equipmentLink: '',
    };
  },
  computed: {
    ...mapGetters('users', ['isSupervisor']),
  },
  created() {
    this.setEqpInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapMutations('users', ['SET_MY_CURRENT_COMPANY']),
    setEqpInfo() {
      if (this.isSupervisor) {
        this.equipmentText = '슈퍼바이저';
        this.equipmentLink = '/users';
      } else {
        this.equipmentText = '스태프';
        this.equipmentLink = '/equipments';
      }
    },
    setCurrentCompany(role) {
      this.SET_MY_CURRENT_COMPANY(role);
      if (role === 'F') {
        this.$router.push('/equipment-groups').catch((err) => err);
      } else {
        this.$router.push(this.equipmentLink).catch((err) => err);
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'RoleSelection';
</style>
